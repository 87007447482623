import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import manastirLogo from "../../content/assets/logo-black.png"

import indexStyles from "./index.module.css"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    let posts_categories = []
    posts.map(item => {
      posts_categories.push(item.node.frontmatter.department)
    })

    const departments = [...new Set(posts_categories)]

    let companyLogo = id => {
      let logo
      id = id * 1
      switch (id) {
        case 1:
          logo = manastirLogo
          break
        default:
          break
      }

      return <img className={indexStyles.company_logo} src={logo} />
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Tüm İşler" />
        {departments.map(department => (
          <div>
            <h2 className={indexStyles.department_title}>{department}</h2>
            <div className={indexStyles.department_wrapper}>
              {posts
                .filter(post => post.node.frontmatter.department === department)
                .map(post => (
                  <div className={indexStyles.job_card}>
                    <img
                      src={
                        post.node.frontmatter.cover.childImageSharp.fluid.src
                      }
                    />
                    <div className={indexStyles.job_card__body}>
                      <Link
                        className={indexStyles.job_card__title}
                        to={post.node.fields.slug}
                      >
                        {post.node.frontmatter.title}
                      </Link>
                      {/* {companyLogo(post.node.frontmatter.company_logo)} */}
                    </div>
                    <Link
                      className={indexStyles.job_card__link}
                      to={post.node.fields.slug}
                    ></Link>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___id] }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            department
            company_logo
            cover {
              childImageSharp {
                id
                fluid {
                  src
                  srcSet
                  sizes
                  originalImg
                  originalName
                }
              }
            }
          }
        }
      }
    }
  }
`
